import {Dialog, Popover, Transition, Disclosure} from "@headlessui/react";
import * as React from "react";
import {Fragment, useContext, useEffect, useState} from "react";
import GlobalContext from "../../context/GlobalContext";
import {graphql, Link, navigate, useStaticQuery} from "gatsby";
import {MenuAlt4Icon, XIcon} from "@heroicons/react/outline";
import {GatsbyImage} from "gatsby-plugin-image";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const Header = () => {
    const [open, setOpen] = useState(false)
    const gContext = useContext(GlobalContext);
    const allImagesQuery = graphql`
            query {
              allFile(
                filter: {
                    relativeDirectory: { regex: "/(prodotti)/" },
                    extension: {ne: "svg"}
                }
              ) {
                edges {
                  node {
                    base
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP]     
                        placeholder: NONE          
                        quality: 100
                      )
                    }
                  }
                }
              }
            }
          `
    const {
        allFile: {edges: images},
    } = useStaticQuery(allImagesQuery)
    const [navigation, setNavigation] = useState({});
    useEffect(() => {
        setNavigation({
            menu: [
                {name: 'Home', href: 'index'},
                {name: 'Prodotti', popover: 'prodotti'},
                {name: 'Contributi', href: 'contributi'},
                {name: 'Installazioni', href: 'installazioni'},
                {name: 'News', href: 'news'},
                {name: 'Chi siamo', href: 'about'},
                {name: 'Contatti', href: 'contatti'},
            ],
            social: [
                {
                    name: 'Facebook',
                    href: 'https://www.facebook.com/tappezzeriaorsillipisa/',
                    icon: (props) => (
                        <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none"
                             strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3"/>
                        </svg>
                    ),
                },
                {
                    name: 'Instagram',
                    href: 'https://www.instagram.com/tappezzeria_orsilli/?hl=it',
                    icon: (props) => (
                        <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none"
                             strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <rect x="4" y="4" width="16" height="16" rx="4"/>
                            <circle cx="12" cy="12" r="3"/>
                            <line x1="16.5" y1="7.5" x2="16.5" y2="7.501"/>
                        </svg>
                    ),
                },
            ],
            product: [
                {
                    name: 'Tende da sole',
                    href: 'tendedasole',
                    image: 'placeholder-tendedasole.jpg',
                },
                {
                    name: 'Pergole',
                    href: 'pergole',
                    image: 'placeholder-pergole.jpg',
                },
                {
                    name: 'Pergole bioclimatiche',
                    href: 'pergolebioclimatiche',
                    image: 'placeholder-bioclimatiche.jpg',
                },
                // {
                //     name: 'Vele ombreggianti',
                //     href: 'veleombreggianti',
                //     image: 'placeholder-veleombreggianti.jpg',
                // },
                {
                    name: 'Rivestimenti',
                    href: 'rivestimenti',
                    image: 'placeholder-rivestimenti.jpg',
                },
                {
                    name: 'Arredamento di interni',
                    href: 'arredamentointerni',
                    image: 'placeholder-interni.jpg',
                },
            ],
        })
    }, [gContext])
    return (
        <Popover as="header" className="fixed inset-x-0 top-0 z-50 header bg-white shadow-md">
            <nav className="px-5 py-3 relative bg-transparent flex items-center justify-between w-full" aria-label="Global">
                <a href="/">
                    <img className="w-auto h-10 sm:h-12 lg:h-16" src={'/logo.svg'} alt="Orsilli" width="197" height="72"/>
                </a>
                <button onClick={() => setOpen(true)} type="button" aria-label="menu" className="bg-transparent rounded-full p-2 inline-flex xl:hidden items-center justify-center hover:opacity-50 focus:outline-none focus:ring-transparent transition duration-300 ease-in-out text-black">
                    <MenuAlt4Icon className="h-7 w-7" aria-hidden="true"/>
                </button>
                <div className="divide-x divide-black flex-1 hidden xl:flex justify-end min-h-[50px]">
                    <div className="flex items-center space-x-3">
                        {(navigation?.menu || []).map((item) => (
                            <div key={item.name}>
                                {item.popover === 'prodotti' ? (
                                    <Popover className="relative">
                                        {({ open }) => (
                                            <>
                                                <Popover.Button className="text-base uppercase link-underline">
                                                    {gContext.t(item.name)}
                                                </Popover.Button>

                                                <Transition
                                                    as={Fragment}
                                                    enter="transition ease-out duration-200"
                                                    enterFrom="opacity-0 translate-y-1"
                                                    enterTo="opacity-100 translate-y-0"
                                                    leave="transition ease-in duration-150"
                                                    leaveFrom="opacity-100 translate-y-0"
                                                    leaveTo="opacity-0 translate-y-1"
                                                >
                                                    <Popover.Panel className="absolute z-10 left-1/2 transform -translate-x-1/2 mt-5 px-2 w-screen max-w-[80%] sm:px-0 lg:max-w-[968px]">
                                                        <div className="rounded-lg shadow-lg relative px-5 py-6 sm:p-8 ring-1 ring-black ring-opacity-5 overflow-hidden bg-white">
                                                            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                                                                {(navigation?.product || []).map((item) => (
                                                                    <Link key={item.name} to={gContext.url(item.href)} className="w-full flex flex-col items-center">
                                                                        <div className="relative w-full rounded-md overflow-hidden mx-auto h-[180px] flex items-center justify-center">
                                                                            <div className="absolute inset-0">
                                                                                <GatsbyImage image={gContext.getImageFromName(images, item.image)}
                                                                                             alt={item.image}
                                                                                             loading="eager"
                                                                                             objectFit="cover"
                                                                                             objectPosition="50% 50%"
                                                                                             className="h-full w-full object-cover" />
                                                                                <div className="absolute inset-0 bg-black/10 mix-blend-multiply"></div>
                                                                            </div>
                                                                            <div className="opacity-0 hover:opacity-100 transition duration-300 ease-in-out relative p-4 flex items-center justify-center w-full">
                                                                                <div className="inline-flex items-center justify-center px-4 py-3 rounded-lg bg-black/50 text-white uppercase font-bold text-base min-h-[50px] w-full max-w-[200px]">{gContext.t('Scopri')}</div>
                                                                            </div>
                                                                        </div>
                                                                        <span className="uppercase text-lg text-center mt-2">{gContext.t(item.name)}</span>
                                                                    </Link>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </Popover.Panel>
                                                </Transition>
                                            </>
                                        )}
                                    </Popover>
                                ) : (
                                    <Link to={gContext.url(item.href)}
                                          onClick={e => {
                                              e.preventDefault();
                                              setTimeout(() => {
                                                  navigate(gContext.url(item.href));
                                              }, 700);
                                          }}
                                          className={`text-base uppercase link-underline ${gContext.isCurrentRoute(item.href) ? 'active' : ''}`}>
                                        {gContext.t(item.name)}
                                    </Link>
                                )}
                            </div>
                        ))}
                    </div>
                    <div className="flex items-center space-x-3 ml-8 pl-3">
                        {(navigation?.social || []).map((item) => (
                            <a key={item.name} href={item.href} target="_blank" rel="noreferrer"
                               className="transition duration-200 ease-in">
                                <span className="sr-only">{item.name}</span>
                                <item.icon className="h-7 w-7 hover:opacity-60 transition duration-150 ease-in-out" aria-hidden="true"/>
                            </a>
                        ))}
                    </div>
                </div>
            </nav>

            {/* Navigation Mobile */}
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="fixed xl:hidden inset-0 overflow-hidden z-[100]" onClose={setOpen}>
                    <div className="absolute inset-0 overflow-hidden">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-in-out duration-500"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in-out duration-500"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="absolute inset-0 bg-black bg-opacity-70 transition-opacity" />
                        </Transition.Child>

                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <div className="pointer-events-auto w-screen max-w-md">
                                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                                        <div className="px-4 sm:px-6 flex items-center justify-end">
                                            <button type="button" className="rounded-md bg-white text-black hover:text-gray-500 focus:outline-none focus:ring-transparent transition duration-150 ease-in-out" onClick={() => setOpen(false)}>
                                                <XIcon className="h-7 w-7" aria-hidden="true" />
                                            </button>
                                        </div>
                                        <div className="relative mt-6 flex-1 px-4 sm:px-6">
                                            <div className="absolute inset-0 px-4 sm:px-6">
                                                <div className="mt-5 flex-grow flex flex-col">
                                                    <nav className="flex-1 px-2 space-y-8 bg-white" aria-label="Sidebar">
                                                        <div className="space-y-1">
                                                            {(navigation?.menu || []).map((item) => (
                                                                <div key={item.name}>
                                                                    {item.popover === 'prodotti' ? (
                                                                        <Disclosure as="div" key={item.name} className="space-y-1">
                                                                            {({ open }) => (
                                                                                <>
                                                                                    <Disclosure.Button
                                                                                        className={classNames(
                                                                                            open ? 'bg-gray-50' : '',
                                                                                            'group w-full text-base flex items-center justify-between rounded-md px-2 py-2 transition ease-in-out duration-200 hover:bg-gray-50'
                                                                                        )}
                                                                                    >
                                                                                        {gContext.t(item.name)}
                                                                                        <svg className={classNames(
                                                                                            open ? 'rotate-180' : '',
                                                                                            'ml-2 flex-shrink-0 h-5 w-5 transform transition ease-in-out duration-150'
                                                                                        )} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                                                            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                                                                        </svg>
                                                                                    </Disclosure.Button>
                                                                                    <Disclosure.Panel className="space-y-1 ml-4">
                                                                                        {(navigation?.product || []).map((item) => (
                                                                                            <Link key={item.name} to={gContext.url(item.href)}
                                                                                                  onClick={e => {
                                                                                                      e.preventDefault();
                                                                                                      setTimeout(() => {
                                                                                                          navigate(gContext.url(item.href));
                                                                                                      }, 700);
                                                                                                  }}
                                                                                                  className={`text-base flex items-center rounded-md px-2 py-2 transition ease-in-out duration-200 ${gContext.isCurrentRoute(item.href) ? 'bg-primary' : 'hover:bg-gray-50'}`}>
                                                                                                {gContext.t(item.name)}
                                                                                            </Link>
                                                                                        ))}
                                                                                    </Disclosure.Panel>
                                                                                </>
                                                                            )}
                                                                        </Disclosure>
                                                                    ) : (
                                                                        <Link to={gContext.url(item.href)}
                                                                              onClick={e => {
                                                                                  e.preventDefault();
                                                                                  setTimeout(() => {
                                                                                      navigate(gContext.url(item.href));
                                                                                  }, 700);
                                                                              }}
                                                                              className={`text-base flex items-center rounded-md px-2 py-2 transition ease-in-out duration-200 ${gContext.isCurrentRoute(item.href) ? 'bg-primary' : 'hover:bg-gray-50'}`}>
                                                                            {gContext.t(item.name)}
                                                                        </Link>
                                                                    )}
                                                                </div>
                                                            ))}
                                                        </div>
                                                        <div className="border-t border-black">
                                                            <h3 className="text-sm tracking-wider uppercase px-2 opacity-60 py-4">Social</h3>
                                                            <div className="grid grid-cols-4 gap-2" role="group">
                                                                {(navigation?.social || []).map((item) => (
                                                                    <a key={item.name} href={item.href} target="_blank" rel="noreferrer"
                                                                       className="transition duration-200 ease-in">
                                                                        <span className="sr-only">{item.name}</span>
                                                                        <item.icon className="h-7 w-7 hover:opacity-50 transition duration-150 ease-in-out" aria-hidden="true"/>
                                                                    </a>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </nav>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </Popover>
    )
}
export default Header
